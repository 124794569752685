API.ContactForm = {};

API.ContactForm.HandleResponse = function(data) {
  var messageTemplate = `
    <div class="row" style="margin-bottom: 0px; margin-top: 10px;">
      <div class="col s12">
        <div class="card-panel ${data.error ? "error" : "success"}">
          <span class="white-text">${data.message}</span>
        </div>
      </div>
    </div>
    `;
  var item = $(messageTemplate);
  $("#contact-form-status").append(item);
  setTimeout(function() {
    item.remove();
  }, 2000);

  return !data.error;
}
API.ContactForm.SetupAjax = function() {

  // capture submit
  $('form#contact-form').submit(function() {
    $theForm = $(this);

    // send xhr request
    $.ajax({
      type: $theForm.attr('method'),
      url: $theForm.attr('action'),
      data: $theForm.serialize(),
      success: function(data) {
        API.ContactForm.HandleResponse(data);
      }
    });

    // prevent submitting again
    return false;
  });
}

$(function() {
  API.ContactForm.SetupAjax();
})
